import React from 'react';
import { Box } from "@mui/material";

interface VideoFooterProps {
  children?: React.ReactNode;
}

function VideoFooter(props: VideoFooterProps) {
  const { children } = props;
  return (
    <Box
     sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 60,
      backgroundColor: (theme) => theme.palette.primary.main,
    }}>
      {children}
    </Box>
  );
}

export default VideoFooter;