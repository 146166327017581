import React from "react";
import { Box, Button, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface CodeInputProps {
    error: string;
    onSubmit: (code: string) => void;
}

function CodeInput(props: CodeInputProps) {
    const { error, onSubmit } = props;
    const [code, setCode] = useState<string>("");

    function onSubmitForm(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        onSubmit(code);
    }

    const { t, i18n } = useTranslation();

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <form onSubmit={onSubmitForm}>
                <FormControl
                    error={!!error}
                    variant="outlined"
                    size="small"
                    sx={{
                        flex: 1,
                        maxWidth: 250,
                    }}
                >
                    <InputLabel htmlFor="input-code">
                        {t("EnterCodeHere")}
                    </InputLabel>
                    <OutlinedInput
                        id="input-code"
                        aria-describedby="input-code-error-text"
                        label="Enter Your Code Here"
                        value={code}
                        onChange={(event) => setCode(event.target.value.toUpperCase())}
                        autoFocus
                        endAdornment={
                            <InputAdornment position="end" sx={{ visibility: code.length > 0 ? "visible" : "hidden" }}>
                                <IconButton onClick={() => setCode("")} onMouseDown={(event) => event.preventDefault()} edge="end">
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <FormHelperText id="input-code-error-text">{error}</FormHelperText>
                </FormControl>
                <Button
                    type="submit"
                    variant="contained"
                    color="success"
                    disableElevation
                    sx={{
                        height: 40, // Match height of text field.
                        ml: 1,
                    }}
                >
                    {t("Begin")}
                </Button>
            </form>
        </Box>
    );
}

export default CodeInput;
