import React from 'react';
import { styled } from '@mui/material/styles';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import MirrorMode from '../enum/MirrorMode';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  position: 'relative',
  pointerEvents: 'auto',
  backgroundColor: 'white',
  left: 0,
  bottom: 0,
  marginTop: 10,
  marginBottom: 10,
  '&:not(:first-of-type)': {
    marginRight: 20,
  },
});

const StyledToggleButton = styled(ToggleButton)({
  padding: '5px 15px', // Matches @mui Button.
});

interface MirrorToggleProps {
  value: MirrorMode;
  onChange: (newValue: MirrorMode) => void;
}

function MirrorToggle(props: MirrorToggleProps) {
  const { value, onChange } = props;

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
    }}>  
      <StyledToggleButtonGroup
        color="primary"
        value={value}
        exclusive
        onChange={(event: React.MouseEvent<HTMLElement>, newValue: MirrorMode) => {
          if (newValue !== null) {
            onChange(newValue)
          }
        }}
      >
        <StyledToggleButton style={{ width: 90 }} value="mirror">
          Mirror
        </StyledToggleButton>
        <StyledToggleButton style={{ width: 90 }} value="off">
          Off
        </StyledToggleButton>
      </StyledToggleButtonGroup>
    </div>
  );
}

export default MirrorToggle;
