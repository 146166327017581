export default class ErrorUtils {
  // Convert a thrown error of any type to a string.
  static toString(error: unknown): string {
    if (error instanceof Error) {
      return error.message;
    } else {
      return String(error);
    }
  }
}
