import React from 'react';
import { Typography } from "@mui/material";

interface OverlayTextProps {
  message: string;
}

function OverlayText(
  props: OverlayTextProps
) {
  const { message } = props;

  return <div style={{
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // padding: 20,
  }}>
    <div style={{
      minWidth: '50%',
      color: 'white',
      backgroundColor: 'rgb(0, 0, 0, 0.5)',
      padding: 20,
      textAlign: 'center',
    }}>
      <Typography variant="h5">{message}</Typography>
    </div>
  </div>
}

export default OverlayText;
