import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';

const savedLanguage = JSON.parse(localStorage.getItem('i18nextLng') || '"en"');
export const supportedLanguages = ["en", "da"] as const;
i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        fallbackLng: "da",
        lng: savedLanguage,
        supportedLngs: supportedLanguages,
        backend: {
            loadPath: '/locales/{{lng}}.json',
        },
        interpolation: {
            escapeValue: false,
        }
    });


export default i18n;