import React, { useState } from 'react';
import NavBar from '../view/NavBar';
import MirrorToggle from '../view/MirrorToggle';
import MirrorMode from '../enum/MirrorMode';
import OverlayText from '../view/OverlayText';
import { AppState } from '../main/AppState';
import VideoFooter from '../view/VideoFooter';
import VideoHeader from '../view/VideoHeader';
import Video from '../view/Video';

interface VideoPageProps {
  state: AppState;
  onStop: () => void;
  style?: React.CSSProperties;
}

// Video steaming page. 
const VideoPage = React.forwardRef(function VideoPage(
  props: VideoPageProps,
  ref: React.ForwardedRef<HTMLVideoElement>
) {
  const { state, onStop, style } = props;
  const [mirrorMode, setMirrorMode] = useState<MirrorMode>('mirror');
  const overlayText = state.overlayText;

  return (
    <div style={{
      ...style,
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
    }}>
      <NavBar />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}>
        <VideoHeader onStop={onStop} />
        <Video
          ref={ref}
          mirrored={mirrorMode === 'mirror'}
          style={{
            flex: 1,
          }}
        >
          {overlayText && <OverlayText
            message={overlayText}
          />}
        </Video>
        <VideoFooter>
          <MirrorToggle
            value={mirrorMode}
            onChange={setMirrorMode}
          />
        </VideoFooter>
      </div>
    </div>
  );
});

export default VideoPage;
