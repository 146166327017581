import React from 'react';
import { CssBaseline } from '@mui/material';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import MainPage from '../page/MainPage';

let theme = createTheme({
  palette: {
    primary: {
      main: '#ADAEAF', //  Earfab grey.
    },
    secondary: {
      main: '#92be00', // Hearables green.
    },
    success: {
      main: '#0EAF4F', // Earfab green
      contrastText: 'white'
    }
  },
});
theme = responsiveFontSizes(theme);

function App() {
  return (
    <>
      {/* Normalise CSS */}
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <MainPage />
      </ThemeProvider>
    </>
  );
}

export default App;
