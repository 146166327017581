import { useState } from 'react'
import { textForRemoteMessage } from '../remote/RemoteTextMessage';
import RemoteState, { ExitReason } from '../remote/RemoteState'
import { useTranslation } from 'react-i18next';

// Current state of the app used to render the video UI.
export interface AppState {
  lastRemoteTime: number | undefined;
  overlayText: string | undefined;
  exitReason: ExitReason | undefined;
}

export default function useAppState() {
  const [state, setState] = useState<AppState>({
    lastRemoteTime: undefined,
    overlayText: 'Not connected',
    exitReason: undefined,
  })

  const [t, i18n] = useTranslation();
  
  // Handle remote state sent by phone app.
  function onRemoteState(remote: RemoteState) {
    setState((state: AppState) => {
      const lastRemoteTime = state.lastRemoteTime;
      const remoteTime = remote.time;
      
      // Ignore out of order messages.
      if (lastRemoteTime && remoteTime < lastRemoteTime) {
        return state
      }

      // Translate remote message code to text.
      const overlayText = textForRemoteMessage(remote.message, t);

      return {
        lastRemoteTime: remoteTime,
        overlayText: overlayText,
        exitReason: remote.exit,
      }
    })
  }

  // Handle disconnection.
  function onDisconnect() {
    setState((state: AppState) => {
      let overlayText = state.overlayText;

      switch (state.exitReason) {
        case 'complete':
          overlayText = t("ScanComplete")!;
          break;
        case 'abort':
          overlayText = t("ScanAbort")!;
          break;
        default:
          overlayText = t("ScanDisconnect")!;
          break;
      }

      return {
        lastRemoteTime: undefined,
        overlayText: overlayText,
        exitReason: state.exitReason,
      }
    })
  }

  return {
    state,
    onRemoteState,
    onDisconnect,
  }
}
