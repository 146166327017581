import React from 'react';

interface VideoProps {
  mirrored: boolean;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const Video = React.forwardRef(function Video(
  props: VideoProps,
  ref: React.ForwardedRef<HTMLVideoElement>
) {
  const { mirrored, style, children } = props;

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      ...style,
    }}>
      <div style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <div style={{
          position: 'relative',
          height: '100%',
          width: '100%',
        }}>
          <video
            ref={ref}
            autoPlay
            playsInline
            muted
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              backgroundColor: 'grey',
              transform: `scale(${mirrored ? 1 : -1}, 1)`, // Note: Video is mirrored by default from app.
            }}
          />
          {children}
        </div>
      </div>
    </div>
  );
});

export default Video;
