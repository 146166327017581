import React from "react";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { styled } from "@mui/material/styles";

import { useTranslation } from "react-i18next";

const StyledBox = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(1),
}));

interface ConnectingDialogProps {
    open: boolean;
    onCancel: () => void;
}

export default function ConnectingDialog(props: ConnectingDialogProps) {
    const { open, onCancel } = props;

    const [t, i18n] = useTranslation();

    return (
        <Dialog
            open={open}
            // onClose={onCancel} // Cancel on click away/Escape.
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{t("ConnectingToPhone")}</DialogTitle>
            <DialogContent>
                <StyledBox>
                    <CircularProgress />
                </StyledBox>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    {t('Cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
