import React from 'react';
import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import TipsDialog from "../dialog/TipsDialog";
import { useTranslation } from 'react-i18next';

interface VideoHeaderProps {
  onStop?: () => void;
}

function VideoHeader(props: VideoHeaderProps) {
  const { onStop } = props;
  const [showTips, setShowTips] = useState(false);
  const [t, i18n] = useTranslation();

  return (
    <>
      <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 60,
        paddingLeft: 3,
        paddingRight: 3,
        backgroundColor: (theme) => theme.palette.primary.main,
      }}>
        <div
          style={{
            display: 'flex',
          }}
        >
          <Typography variant="h6" color="white">{t('tip1')}</Typography>
          <Button
            variant="contained"
            color="info"
            disableElevation
            onClick={() => setShowTips(true)}
            sx={{
              marginLeft: 2,
            }}
          >
            {t('MoreTips')}
          </Button>
        </div>

        <Button
          variant="contained"
          color="error"
          disableElevation
          onClick={onStop}
        >
          {t('Close')}
        </Button>
      </Box>
      <TipsDialog  open={showTips} onCancel={() => setShowTips(false)}  />
    </>
  );
}

export default VideoHeader;
