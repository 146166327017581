import React, { useState } from 'react';
import { Paper, Typography } from '@mui/material';
import CodeInput from '../view/CodeInput';
import NavBar from '../view/NavBar';
import ConnectingDialog from '../dialog/ConnectingDialog';
import ErrorUtils from '../utils/ErrorUtils';
import {useTranslation} from 'react-i18next';

interface FrontPageProps {
  onStart: (code: string) => Promise<void>;
  onCancel: () => void;
  message: string;
  style?: React.CSSProperties;
}

// Initial login/code page.
function FrontPage(props: FrontPageProps) {
  const { onStart, onCancel, message, style } = props;
  const [error, setError] = useState<string>('');
  const [connecting, setConnecting] = useState(false);

  const { t, i18n } = useTranslation();

  function startVideo(code: string) {
    // Clear error.
    setError('');
    setConnecting(true);
    onStart(code).then(() => {
      console.log("Connected!");
      setConnecting(false);
    }).catch((error) => {
      console.error(error);
      setConnecting(false);
      setError(ErrorUtils.toString(error))
    });
  }

  function cancelVideo() {
    const cancelledMessage = t('ConnectionCancelled');

    onCancel();
    setError(cancelledMessage);
    setConnecting(false);
  }

  return (
    <div style={{
      ...style,
      flexDirection: 'column',
      height: '100vh',
    }}>
      <NavBar />
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgb(231, 235, 240)',
        }}>
          <Paper elevation={3} sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: 700,
            height: 500,
          }}>
            <CodeInput
              error={error}
              onSubmit={startVideo}
            />
            {/* TODO: Tidy up the layout */}
            {!!message && <Typography variant="h5" color="primary" sx={{
              marginTop: 10,
            }}><b>{message}</b></Typography>}
          </Paper>
        </div>
      <ConnectingDialog open={connecting} onCancel={cancelVideo} />
    </div>
  );
}

export default FrontPage;
