export class CognitoConfig {
  // IDP region.
  region = 'ap-southeast-2';

  // Identitiy pool id.
  identityPoolId = 'ap-southeast-2:ad849d7c-16ca-4465-bacf-b16d193cb078'; // 'mirror-kinesis-identity-pool-viewer'

  // User pool id.
  userPoolId = 'ap-southeast-2_a2EOW8pBe'; // 'mirror-kinesis-user-pool'

  // App client id.
  clientId = '192bt66tqb1sq89fioq0gmvvs4'; // 'mirror-kinesis-app-client-web'

  // IDP endpoint.
  get idpEndpoint() {
    return `cognito-idp.${this.region}.amazonaws.com/${this.userPoolId}`;
  }
  
  // TODO: Use an unauthenticated user instead.
  username = 'hearables_scan_demo_web';
  password = '8,R45;!`qvbX&tc&ot@F*kTYKZp9}\\\\S%';
}
