import { useTranslation } from "react-i18next";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { supportedLanguages } from '../utils/i18n';
export default function LanguageSwitcher() {
    const { i18n } = useTranslation();
    const [language, setLanguage] = useLocalStorage('i18nextLng', 'en');

    function changeLanguage(language: string) {
        i18n.changeLanguage(language);
        setLanguage(language);
    }

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 6,
            marginLeft: "auto",
            marginRight: 10
        }}>
            {supportedLanguages.map((currLang, idx) => (
                <>
                    <div
                        key={language}
                        style={{
                            cursor: 'pointer',
                            color: language === currLang ? '#ADAEAF' : 'black',
                            fontWeight: language === currLang ? 'bold' : 'normal',
                        }}
                        onClick={() => changeLanguage(currLang)}
                    >
                        {currLang.toUpperCase()}
                    </div>
                    {idx < supportedLanguages.length - 1 &&
                        (
                            <span
                                key={language + "_separator"}
                                style={{
                                    fontSize: 14,
                                }}
                            >|</span>
                        )}
                </>
            ))}
        </div>
    );
}
