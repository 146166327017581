function getRandomClientId() {
  return Math.random()
    .toString(36)
    .substring(2)
    .toUpperCase();
}

export type KinesisRouting = 'stun' | 'turn'

const availableRouting = Array<KinesisRouting>('turn', 'stun')

export class KinesisConfig {
  // Region of Kinesis Signaling, TURN & STUN servers.
  region = 'ap-southeast-2';

  // Signaling channel prefix.
  channelNamePrefix = 'scan-';

  // Must be unique for each connected client.
  clientId = getRandomClientId();

  // Available routing methods.
  routing: Array<KinesisRouting> = availableRouting;

  // Force video to relay through turn server?
  forceRelay = false && availableRouting.includes('turn');

  // Note: Not supported by Alexa devices
  useTrickleICE = true;

  // Endpoint for STUN server.
  get stunEndpoint() {
    return `stun:stun.kinesisvideo.${this.region}.amazonaws.com:443`
  }
}
