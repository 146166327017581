import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { KinesisVideo } from "@aws-sdk/client-kinesis-video";
import { KinesisVideoSignaling } from "@aws-sdk/client-kinesis-video-signaling";
import { AuthenticationDetails, CognitoUserSession, CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';

import KVSWebRTC from './aws/KVSWebRTC';
import { CognitoConfig } from "../config/CognitoConfig";
import { KinesisConfig } from "../config/KinesisConfig";
import { SignalingClient } from "./aws/SignalingClient";

// Async wrapper for non async function CognitoUser.authenticateUser()
async function authenticateUser(
    cognitoUser: CognitoUser,
    authenticationDetails: AuthenticationDetails,
): Promise<CognitoUserSession> {
    return await new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: resolve,        
            onFailure: reject,
        });
    });
}

export interface ViewerConfig {
    cognitoConfig: CognitoConfig,
    kinesisConfig: KinesisConfig,
    code: string,
    remoteView: HTMLVideoElement,
    onRemoteDataMessage: (data: unknown) => void,
    onDisconnected: () => void,
}

// Note: Not currently provided by tslib.
interface RTCIceCandidateStats {
    address: string,
    candidateType: string,
    ip: string | undefined,
    networkType: string,
    port: number,
    protocol: string,
}

export class Viewer {
    private signalingClient: SignalingClient | null = null
    private peerConnection: RTCPeerConnection | null = null
    private localDataChannel: RTCDataChannel | null = null
    private remoteView: HTMLVideoElement | null = null
    private remoteStream: MediaStream | null = null
    private beforeUnload: (() => void) | null = null;
    private config: ViewerConfig | undefined;
    private disconnectTimeout: NodeJS.Timeout | undefined;
    private tryAgainTimeout: NodeJS.Timeout | undefined;
    private statsTimeout: NodeJS.Timeout | undefined;
    private connectionToken: unknown | undefined;

    tryAgainLater() {
        this.close();

        if (!this.config) {
            return; // No longer want connection.
        }

        if (this.tryAgainTimeout) {
            return; // Already waiting.
        }
        
        this.tryAgainTimeout = setTimeout(() => {
            this.tryAgainTimeout = undefined;
            
            this.tryToConnect();
        }, 1000) // Note: Takes a while to establish connection so a short timeout is ok.
    }

    private tryToConnect() {
        if (!this.config) {
            return; // No longer want connection.
        }

        this.connect(this.config).then(() => {
            console.log('[VIEWER] Connection complete');
        }).catch(error => {
            console.log('[VIEWER] Connection error:', error);
            this.tryAgainLater();
        });
    }

    async connect(config: ViewerConfig) {
        // Stop any previous connection.
        this.disconnect();

        this.config = config;

        // Watch for unload and stop video.
        this.beforeUnload = () => {
            this.disconnect();
        };
        window.addEventListener('beforeunload', this.beforeUnload);

        this.remoteView = config.remoteView;

        const authenticationData = {
            Username: config.cognitoConfig.username,
            Password: config.cognitoConfig.password,
        };
        const authenticationDetails = new AuthenticationDetails(
            authenticationData
        );
        const poolData = {
            UserPoolId: config.cognitoConfig.userPoolId,
            ClientId: config.cognitoConfig.clientId
            // Note: Must NOT have a client secret set in AWS console!
        };
        const userPool = new CognitoUserPool(poolData);
        const userData = {
            Username: config.cognitoConfig.username,
            Pool: userPool,
        };
        const cognitoUser = new CognitoUser(userData);
        
        const userSession = await authenticateUser(
            cognitoUser,
            authenticationDetails,
        );
        const userToken = userSession.getIdToken().getJwtToken();

        const options = {
            identityPoolId: config.cognitoConfig.identityPoolId,
            clientConfig: {
                region: config.cognitoConfig.region,
            },
            logins: {
                [config.cognitoConfig.idpEndpoint]: userToken,
            },
        };

        // TODO: Retrieve credentials from unauthenticated user instead.
        const credentials = await fromCognitoIdentityPool(options)();

        // Create KVS client
        const kinesisVideoClient = new KinesisVideo({
            region: config.kinesisConfig.region,
            credentials: credentials,
        });

        const channelName = config.kinesisConfig.channelNamePrefix + config.code;

        // Get signaling channel ARN
        const describeSignalingChannelResponse = await kinesisVideoClient
            .describeSignalingChannel({
                ChannelName: channelName,
            });
        const channelARN = describeSignalingChannelResponse.ChannelInfo?.ChannelARN;
        console.log('[VIEWER] Channel ARN: ', channelARN);

        if (!channelARN) {
            throw new Error(`Could not get ARN for channel: '${channelName}'`)
        }

        // Get signaling channel endpoints
        const getSignalingChannelEndpointResponse = await kinesisVideoClient
            .getSignalingChannelEndpoint({
                ChannelARN: channelARN,
                SingleMasterChannelEndpointConfiguration: {
                    Protocols: ['WSS', 'HTTPS'],
                    Role: KVSWebRTC.Role.VIEWER,
                },
            });
        type ResourceEnpointMap = { [key: string]: string };
        const endpointsByProtocol = getSignalingChannelEndpointResponse.ResourceEndpointList?.reduce<ResourceEnpointMap>((endpoints, endpoint) => {
            if (endpoint.Protocol && endpoint.ResourceEndpoint) {
                endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
            }
            return endpoints;
        }, {});
        console.log('[VIEWER] Endpoints: ', endpointsByProtocol);

        const httpsEndpoint = endpointsByProtocol?.HTTPS
        if (!httpsEndpoint) {
            throw new Error(`Could not find HTTPS endpoint in: ${JSON.stringify(endpointsByProtocol, null, 2)}`)
        }
        const wssEndpoint = endpointsByProtocol?.WSS
        if (!httpsEndpoint) {
            throw new Error(`Could not find WSS endpoint in: ${JSON.stringify(endpointsByProtocol, null, 2)}`)
        }

        const kinesisVideoSignalingChannelsClient = new KinesisVideoSignaling({
            region: config.kinesisConfig.region,
            credentials: credentials,
            endpoint: httpsEndpoint,
        });

        // Get ICE server configuration
        const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
            .getIceServerConfig({
                ChannelARN: channelARN,
            });
        
        // Build a list of ICE servers.
        const iceServers = [];

        if (config.kinesisConfig.routing.includes('stun')) {
            iceServers.push({ urls: config.kinesisConfig.stunEndpoint });
        }

        if (config.kinesisConfig.routing.includes('turn')) {
            getIceServerConfigResponse.IceServerList?.forEach(iceServer =>
                iceServers.push({
                    urls: iceServer.Uris,
                    username: iceServer.Username,
                    credential: iceServer.Password,
                }),
            );
        }
        console.log('[VIEWER] ICE servers: ', iceServers);

        // Create Signaling Client
        const signalingClient = this.signalingClient = new KVSWebRTC.SignalingClient({
            channelARN,
            channelEndpoint: wssEndpoint,
            clientId: config.kinesisConfig.clientId,
            role: KVSWebRTC.Role.VIEWER,
            region: config.kinesisConfig.region,
            credentials: credentials,
            systemClockOffset: kinesisVideoClient.config.systemClockOffset,
        });

        // Create peer connection.
        const peerConfig: RTCConfiguration = {
            iceServers,
            iceTransportPolicy: config.kinesisConfig.forceRelay ? 'relay' : 'all',
        };
        const peerConnection = this.peerConnection = new RTCPeerConnection(peerConfig);

        // Create data channel for guaranteed messaging.
        // Note: For some reason we NEED to create this otherwise 'peerConnection.ondatachannel'
        // doesn't fire when the remote iOS data channel is added. iOS bug? Or order/timing of ops?
        const dataChannelConfig: RTCDataChannelInit = {};        
        const dataChannel = this.localDataChannel = peerConnection.createDataChannel(
            'webDataChannel',
            dataChannelConfig
        );
        
        //
        // Add event handlers - Beyond this point we need to be careful to check that a
        // connection is still active (and desired) in each event handler.
        //

        // Note: Not 'unhooking' these handlers so messages may come in AFTER disconnect.
        const connectionToken = this.connectionToken = {}
        const notConnected = () => {
            return this.connectionToken !== connectionToken;
        }

        // Note: Must use function variable otherwise 'this' == RTCDataChannel.
        const onMessage = (event: { data: unknown; }) => {
            if (notConnected()) {
                return;
            }
            this.config?.onRemoteDataMessage(event.data);
        }

        // Called when disconnection occurs.
        const onDisconnection = () => {
            if (notConnected()) {
                return;
            }
            this.config?.onDisconnected();
            this.tryAgainLater();
        }

        this.reportStatsAfterTimeout((stats: RTCStatsReport) => {
            // Initially from: https://stackoverflow.com/a/29360179
            stats.forEach(function(stat: RTCStats) {
                if (stat.type === "candidate-pair") {
                    const pair = stat as RTCIceCandidatePairStats;
                    if (pair.nominated && pair.state === "succeeded") {
                        const remote = stats.get(pair.remoteCandidateId) as RTCIceCandidateStats;
                        console.log(
                            "Connected to: " +
                            (remote.ip || remote.address) + ":" +
                            String(remote.port) + " " +
                            remote.protocol + " " +
                            remote.candidateType
                        );
                    }
                }
            });
        });

        // Channel used for guaranteed messaging.
        dataChannel.onmessage = onMessage;
        dataChannel.onclose = event => {
            console.log(`[VIEWER] Local datachannel onclose: ${JSON.stringify(event, null, 2)}`);
        };
        peerConnection.ondatachannel = event => {
            console.log(`[VIEWER] On data channel: ${event.channel.label}`);
            const remoteChannel = event.channel;

            if (notConnected()) {
                return;
            }

            // Channel used for unreliable messaging.
            remoteChannel.onmessage = onMessage;
            remoteChannel.onclose = event => {
                console.log(`[VIEWER] Remote datachannel onclose: ${JSON.stringify(event, null, 2)}`);

                if (notConnected()) {
                    return;
                }
    
                // Note: This is sent back by remote phone app after we sendHangUp() during graceful disconnection.
                onDisconnection();
            }
        };

        async function onOpen(): Promise<void> {
            console.log('[VIEWER] Connected to signaling service');

            if (notConnected()) {
                return;
            }

            // Create an SDP offer to send to the master
            console.log('[VIEWER] Creating SDP offer');
            const sdpOffer = await peerConnection.createOffer({
                offerToReceiveAudio: false,
                offerToReceiveVideo: true,
            });

            if (notConnected()) {
                return;
            }

            console.log('[VIEWER] Setting local description');
            await peerConnection.setLocalDescription(sdpOffer);

            if (notConnected()) {
                return;
            }

            // When trickle ICE is enabled, send the offer now and then send ICE candidates as they are generated. Otherwise wait on the ICE candidates.
            if (config.kinesisConfig.useTrickleICE && peerConnection.localDescription) {
                console.log('[VIEWER] Sending SDP offer');
                signalingClient.sendSdpOffer(peerConnection.localDescription);
            }
            console.log('[VIEWER] Generating ICE candidates');
        }
        signalingClient.on('open',() => {
            onOpen().catch(err => console.error(err))
        });

        async function onAnswer(answer: RTCSessionDescription): Promise<void> {
            // Add the SDP answer to the peer connection
            console.log('[VIEWER] Received SDP answer');

            if (notConnected()) {
                return;
            }

            console.log('[VIEWER] Setting remote description');
            await peerConnection.setRemoteDescription(answer);
        }
        signalingClient.on('sdpAnswer', (answer: RTCSessionDescription) => {
            onAnswer(answer).catch(err => console.error(err))
        });

        async function onIceCandidate(candidate: RTCIceCandidate): Promise<void> {
            // Add the ICE candidate received from the MASTER to the peer connection
            console.log('[VIEWER] Received ICE candidate');

            if (notConnected()) {
                return;
            }

            await peerConnection.addIceCandidate(candidate);
        }
        signalingClient.on('iceCandidate', (candidate: RTCIceCandidate) => {
            onIceCandidate(candidate).catch(err => console.error(err))
        });

        signalingClient.on('close', () => {
            console.log('[VIEWER] Disconnected from signaling channel');

            if (notConnected()) {
                return;
            }

            // Retry later.
            onDisconnection();
        });

        signalingClient.on('error', error => {
            console.error('[VIEWER] Signaling client error: ', error);
        });

        // Note: Not using this - it's better for app-side code to send a guaranteed 'exit' message.
        // Then we know ALL guaranteed messages up to that point have been received.
        // signalingClient.on('hangUp', async answer => {
        //     console.log('[VIEWER] Received hang up');
        // });

        // Send any ICE candidates to the other peer
        peerConnection.addEventListener('icecandidate', ({ candidate }) => {
            console.log('[VIEWER] ICE candidate requested');

            if (notConnected()) {
                return;
            }

            if (candidate) {
                console.log('[VIEWER] Generated ICE candidate');

                // When trickle ICE is enabled, send the ICE candidates as they are generated.
                if (config.kinesisConfig.useTrickleICE) {
                    console.log('[VIEWER] Sending ICE candidate');
                    signalingClient.sendIceCandidate(candidate);
                }
            } else {
                console.log('[VIEWER] All ICE candidates have been generated');

                // When trickle ICE is disabled, send the offer now that all the ICE candidates have ben generated.
                if (!config.kinesisConfig.useTrickleICE && peerConnection.localDescription) {
                    console.log('[VIEWER] Sending SDP offer');
                    signalingClient.sendSdpOffer(peerConnection.localDescription);
                }
            }
        });

        console.log('[VIEWER] Starting viewer connection');
        signalingClient.open();

        peerConnection.addEventListener('icecandidateerror', event => {
            console.log(`[VIEWER] Received icecandidateerror: ${JSON.stringify(event, null, 2)}`);
        });

        // Note: There are a number of cases where we should close the call and retry. See closeVideoCall() here:
        // https://developer.mozilla.org/en-US/docs/Web/API/WebRTC_API/Signaling_and_video_calling

        peerConnection.addEventListener('signalingstatechange', event => {
            console.log(`[VIEWER] Received signalingstatechange: ${JSON.stringify(event, null, 2)}`);

            if (notConnected()) {
                return;
            }

            // See: https://developer.mozilla.org/en-US/docs/Web/API/WebRTC_API/Signaling_and_video_calling
            switch (peerConnection.signalingState) {
                case 'closed':
                    onDisconnection();
                    break;
            }
        });

        const onConnected = () => {
            // Install a handler to watch for disconnects and retry connection.
            peerConnection.addEventListener('iceconnectionstatechange', event => {
                console.log(`[VIEWER] Received iceconnectionstatechange: ${JSON.stringify(event, null, 2)}`);

                if (notConnected()) {
                    return;
                }
    
                // See: https://developer.mozilla.org/en-US/docs/Web/API/WebRTC_API/Signaling_and_video_calling
                switch (peerConnection.iceConnectionState) {
                    // Note: Although the MDN article doesn't say to reboot on 'disconnected' I've found it's also
                    // a fairly terminal state and is the ONLY message that comes through if eg. the phone app crashes.
                    case 'disconnected':
                    case 'closed':
                    case 'failed':
                        onDisconnection();
                        break;
                }
            });
        }

        return await new Promise((resolve, reject) => {
            peerConnection.addEventListener('track', event => {
                console.log('[VIEWER] Received remote track');
    
                if (notConnected()) {
                    reject(new Error('No longer connected.'));
                    return;
                }
    
                // Access latest value (may be undefined now).
                const remoteView = this.remoteView;
    
                if (remoteView) {
                    this.remoteStream = event.streams[0];
                    remoteView.srcObject = this.remoteStream;
                }

                // TODO: What happens if reconnection/stop video comes in before this completes?
    
                onConnected();
                resolve(true);
            });
        })
    }

    gracefulDisconnect() {
        // Connection no longer required.
        this.config = undefined;

        // Send a hang up message to the phone.
        this.signalingClient?.sendHangUp();

        // Hard disconnect if phone app hasn't disconnected us gracefully before timeout.
        this.disconnectTimeout = setTimeout(() => {
            this.close();
        }, 3000);
    }

    disconnect() {
        this.config = undefined;

        this.close();
    }

    // Dump connection method and ip address every so often.
    private reportStatsAfterTimeout(reporter: (stats: RTCStatsReport) => void) {
        this.statsTimeout = setTimeout(() => {
            if (!this.peerConnection) {
                return; // Disconnected.
            }

            this.peerConnection.getStats().then((stats) => {
                reporter(stats);
            }).catch((error) => {
                console.error('[VIEWER] getStats() error:', error);
            }).finally(() => {
                // Do it again later.
                this.reportStatsAfterTimeout(reporter);
            });
        }, 1000);
    }

    private close() {
        console.log('[VIEWER] Stopping viewer connection');

        this.connectionToken = undefined;

        if (this.statsTimeout) {
            clearTimeout(this.statsTimeout);
            this.statsTimeout = undefined;
        }

        if (this.disconnectTimeout) {
            clearTimeout(this.disconnectTimeout);
            this.disconnectTimeout = undefined;
        }

        if (this.remoteStream) {
            this.remoteStream.getTracks().forEach(track => track.stop());
            this.remoteStream = null;
        }

        if (this.remoteView) {
            this.remoteView.srcObject = null;
        }

        if (this.localDataChannel) {
            this.localDataChannel.close()
            this.localDataChannel = null;
        }

        if (this.peerConnection) {
            this.peerConnection.close();
            this.peerConnection = null;
        }
        
        if (this.signalingClient) {
            this.signalingClient.close();
            this.signalingClient = null;
        }

        if (this.beforeUnload) {
            window.removeEventListener('beforeunload', this.beforeUnload);
            this.beforeUnload = null;
        }
    }
}
