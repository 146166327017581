

// A text message sent by the remote phone app.

import { TFunction } from "i18next";

// Note: Matches list of messages in ScanMessage enum on iOS.
export enum RemoteTextMessage {
  /// Point the display camera at your left ear to begin.
  pointCameraAtLeftEarToBegin = "PDLE",
  /// Point the display camera at your right ear to begin.
  pointCameraAtRightEarToBegin = "PDRE",
  /// Camera permission was denied.
  cameraPermissionDenied = "CPDN",
  /// This iphone does not support TrueDepth.
  noTrueDepth = "NOTD",
  /// Left ear scan is complete.
  leftSideComplete = "LSCP",
  /// Right ear scan is complete.
  rightSideComplete = "RSCP",
}

export function textForRemoteMessage(message: RemoteTextMessage | undefined, translationFunction: TFunction<"translation">): string | undefined {
  if (!message) {
    return undefined;
  }

  switch (message) {
    case RemoteTextMessage.pointCameraAtLeftEarToBegin:
      return translationFunction("pointCameraAtLeftEarToBegin")!;
    case RemoteTextMessage.pointCameraAtRightEarToBegin:
      return translationFunction("pointCameraAtRightEarToBegin")!;
    case RemoteTextMessage.cameraPermissionDenied:
      return translationFunction("cameraPermissionDenied")!
    case RemoteTextMessage.noTrueDepth:
      return translationFunction("noTrueDepth")!;
    case RemoteTextMessage.leftSideComplete:
      return translationFunction("leftSideComplete")!;
    case RemoteTextMessage.rightSideComplete:
      return translationFunction("rightSideComplete")!;
  }
}

export default RemoteTextMessage;
