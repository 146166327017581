import { Box, Typography } from "@mui/material";
import React from 'react';
import LanguageSwitcher from './LanguageSwitcher';

interface NavBarProps {
  children?: React.ReactNode;
}

function NavBar(props: NavBarProps) {
  const { children } = props;
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 60,
      paddingLeft: 2,
      paddingRight: 2,
    }}>
      <a href="https://scan.earfab.dk" style={{
        textDecorationLine: 'none',
      }}>
        <Typography variant="h5" color="primary"><b>Live Scan View</b></Typography>
      </a>

      {children}

      <LanguageSwitcher />

      <a href="https://earfab.com" style={{
        textDecorationLine: 'none',
      }}>
        <Typography variant="h5" color="primary"><b>Earfab</b></Typography>
      </a>
    </Box>
  );
}

export default NavBar;