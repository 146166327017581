import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from "react-i18next";

interface TipsDialogProps {
  open: boolean;
  onCancel: () => void;
}


function TipsDialog(props: TipsDialogProps) {
  const { open, onCancel } = props;

  const [t, i18n] = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('MoreTips')}
      </DialogTitle>
      <DialogContent>
        <ul>
          <DialogContentText><li>{t('tip1')}</li></DialogContentText>
          <DialogContentText><li>{t('tip2')}</li></DialogContentText>
          <DialogContentText><li>{t('tip3')}</li></DialogContentText>
          <DialogContentText><li>{t('tip4')}</li></DialogContentText>
        </ul>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TipsDialog;
