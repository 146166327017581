import React, { useRef, useState } from 'react';
import { Viewer } from '../video/Viewer';
import { CognitoConfig } from '../config/CognitoConfig';
import { KinesisConfig } from '../config/KinesisConfig';
import useAppState from '../main/AppState';
import RemoteState from '../remote/RemoteState';
import FrontPage from './FrontPage';
import VideoPage from './VideoPage';

const cognitoConfig = new CognitoConfig();

// Video regions supported by iPhone app.
const videoRegionDefault = 'ap-southeast-2';
const videoRegions: { [key: string]: string } = {
  A: 'ap-southeast-2', // Sydney, Australia
  C: 'us-west-1', // North California, USA
  G: 'eu-central-1', // Frankfurt, Germany
  J: 'ap-northeast-1', // Tokyo, Japan
  K: 'ap-northeast-2', // Seoul, South Korea
  L: 'eu-west-2', // London, UK
  O: 'us-east-2', // Ohio, USA,
  R: 'us-west-2', // Oregon, USA
};

function MainPage() {
  const { state, onRemoteState, onDisconnect } = useAppState();
  const [showVideo, setShowVideo] = useState(false);
  const [message, setMessage] = useState<string>('');
  const videoElement = useRef<HTMLVideoElement>(null);
  const viewer = useRef<Viewer>(new Viewer());

  // Start video streaming connection.
  async function startVideo(code: string): Promise<void> {
    if (!videoElement.current) {
      throw new Error("Remote view is not set!")
    }

    // Choose correct region.
    const prefix = code.slice(0, 1);
    const videoRegion = videoRegions[prefix] || videoRegionDefault;
    const kinesisConfig = new KinesisConfig();

    kinesisConfig.region = videoRegion;

    // Handle remote message sent by the phone app.
    function onRemoteDataMessage(data: unknown) {
      if (typeof data !== 'string') {
        console.error(`Unknown remote message type '${typeof data}': `, data)
        return;
      }

      const state = JSON.parse(data) as RemoteState;

      onRemoteState(state);

      if (state.exit) {
        // Graceful disconnect sent from iphone side.
        onDisconnect();
        
        // Optionally hide the video view and use error message.
        let errorText = 'Disconnected';
        
        switch (state.exit) {
          case 'complete':
            errorText = "Scan complete!";
            break;
          case 'abort':
            errorText = "The scan was aborted.";
            break;
        }

        setMessage(errorText);
        // Phone app told us to hand up, disconnect immediately.
        viewer.current.disconnect();
        setShowVideo(false);
      }
    }

    function onDisconnected() {
      onDisconnect();
    }

    // Clear message.
    setMessage('');
    return await viewer.current.connect({
      cognitoConfig,
      kinesisConfig: kinesisConfig,
      code,
      remoteView: videoElement.current,
      onRemoteDataMessage,
      onDisconnected,
    }).then(() => {
      setShowVideo(true);
    });
  }

  // Stop video streaming connection.
  function stopVideo() {
    setMessage('');
    // Try to disconnect gracefully by telling the phone app we need to hang up.
    viewer.current.gracefulDisconnect();
    setShowVideo(false);
  }

  // Cancel video streaming connection.
  function onCancelConnection() {
    viewer.current.disconnect();
  }

  return (
    <>
      <FrontPage
          style={{
            display: showVideo ? 'none' : 'flex',
          }}
          onStart={startVideo}
          onCancel={onCancelConnection}
          message={message}
      />
      {/* Note: Must keep video element constructed but hidden so we can pass it to startVideo() */}
      <VideoPage
          style={{
            display: showVideo ? 'flex' : 'none',
          }}
          ref={videoElement}
          state={state}
          onStop={stopVideo}
      />
    </>
  );
}

export default MainPage;
